export default [
  {
    header: 'Main Apps',
    action: 'read',
    resource: 'Main Apps',
  },
  {
    title: 'Inventory',
    icon: 'BoxIcon',
    action: 'read',
    resource: 'Inventory',
    children: [
      {
        title: 'Facility',
        route: 'inventory-facility',
        action: 'read',
        resource: 'Inventory Facility',
      },
      {
        title: 'Infrastructure',
        route: 'inventory-infrastructure',
        action: 'read',
        resource: 'Inventory Infrastructure',
      },
      {
        title: 'Tool',
        route: 'inventory-tool',
        action: 'read',
        resource: 'Inventory Tool',
      },
      {
        title: 'Category',
        route: 'category',
        action: 'read',
        resource: 'Category',
      },
    ],
  },
  {
    title: 'Loan',
    route: 'loan',
    icon: 'ShoppingCartIcon',
    action: 'read',
    resource: 'Loan',
  },
  {
    title: 'Debt',
    route: 'debt',
    icon: 'DollarSignIcon',
    action: 'read',
    resource: 'Debt',
  },
]
