<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="total_notification"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ total_notification }}
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-link
        v-for="notification in notifications"
        :key="notification.id"
      >
        <b-media>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.content }}</small>
          <br>
          <small class="notification-text">{{ notification.created_at }}</small>
        </b-media>
      </b-link>

    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      @click.prevent="handleUpdate"
          >Read all notifications</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
    }
  },
  data() {
    return {
        notifications: [],
        total_notification: 0,
    }
  },
  mounted() {
    this.getNotification()
  },
  methods: {
    getNotification() {
      this.$http.get('/v1/notification')
        .then(response => {
          this.notifications = response.data.data
          this.total_notification = response.data.data.length
        })
        .catch(error => {
          this.errorToast(error.response.data.messages)
        })
    },
    handleUpdate() {
      this.$http.post('/v1/notification')
        .then(() => {
          this.successToast()
          this.getNotification()
        })
        .catch(error => {
          this.errors = error.response.data.errors
          this.errorToast(error.response.data.messages)
        })
    },
    successToast() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Berhasil',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    errorToast(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    },
  },
}
</script>

<style>

</style>
