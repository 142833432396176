export default [
  {
    header: 'Information',
    action: 'read',
    resource: 'Information',
  },
  {
    title: 'Announcement',
    route: 'announcement',
    icon: 'FileTextIcon',
    action: 'read',
    resource: 'Announcement',
  },
]
