export default [
  {
    header: 'Management',
    action: 'read',
    resource: 'Management',
  },
  {
    title: 'Users',
    route: 'user',
    icon: 'UsersIcon',
    action: 'read',
    resource: 'User',
  },
]
