export default [
  {
    title: 'Dashboard',
    icon: 'TrelloIcon',
    route: 'dashboard-student',
    action: 'read',
    resource: 'Dashboard Student',
  },
  {
    title: 'Dashboard Admin',
    icon: 'LayersIcon',
    route: 'dashboard-admin',
    action: 'read',
    resource: 'Dashboard Admin',
  },
]
